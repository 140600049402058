import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectWrapper({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the selected promo from session storage
        const selectedPromo = sessionStorage.getItem('selectedPromo');

        // Check if selectedPromo exists or is not empty
        if (!selectedPromo) {
            navigate('/');
        }
    }, [navigate]);

    return children;
};

export default RedirectWrapper;
