import { useEffect, useRef } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

const NavigationHandler = () => {
    const location = useLocation();
    const navigationType = useNavigationType();
    const prevLocation = useRef(location.pathname);

    useEffect(() => {
        // Get process flow from session storage
        const processFlow = JSON.parse(sessionStorage.getItem('processFlow')) || [];

        // Function to update sequenceNo
        const updateSequenceNo = (newSequenceNo) => {
            sessionStorage.setItem('sequenceNo', newSequenceNo);
            //console.log(`Navigation updated: New sequenceNo is ${newSequenceNo}`);
        };

        // Get current sequence number
        const currentSeqNo = parseInt(sessionStorage.getItem('sequenceNo') || '0', 10);

        // Handle navigation change
        if (prevLocation.current !== location.pathname) {
            if (navigationType === 'PUSH') {
                // Forward navigation
                const newSeqNo = currentSeqNo + 1;
                if (newSeqNo < processFlow.length) {
                    updateSequenceNo(newSeqNo);
                }
            } else if (navigationType === 'POP') {
                // Backward navigation
                const newSeqNo = currentSeqNo - 1;
                if (newSeqNo >= 0) {
                    updateSequenceNo(newSeqNo);
                }
            }
        }

        // Update the ref to the current location after handling
        prevLocation.current = location.pathname;
    }, [location, navigationType]); // Dependency on location and navigationType ensures this runs on navigation change

    return null; // This component does not render anything
};

export default NavigationHandler;
