import Axios from 'axios';

const baseUrl = process.env.REACT_APP_COREAPI;

function axiosFetcher({ url, method = 'GET', data = null, onProgress, onLoading, onData, onError, enabled = true }) {
    // Early return if the function should not be enabled yet
    if (!enabled || !url) {
        onLoading && onLoading(false);
        return Promise.resolve();
    }

    onLoading && onLoading(true);
    let progress = 0; // Reset progress on new request
        
    return Axios.request({
        url: baseUrl + url,
        method: method,
        data: data,
        headers: {
            'Content-Type': 'application/json'
        },
            
        onUploadProgress: (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT')
            ? (progressEvent) => {
                progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) onProgress(progress);
            }
            : null,
        onDownloadProgress: (method.toUpperCase() === 'GET')
            ? (progressEvent) => {
                progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) onProgress(progress);
            }
            : null,
    })
        .then(response => {
            
            onData && onData(response.data);
            onLoading && onLoading(false);
            return response.data; // Resolve the Promise with response data
        })
        .catch(error => {
            console.error("Axios request failed: ", error);
            onError && onError(error);
            onLoading && onLoading(false);
            throw error; // Reject the Promise with the error
        });
}

export default axiosFetcher;
