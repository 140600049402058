import React, {useMemo} from 'react';
import stepsData from '../../data/steps.json';
function StepTracker({currentStep})
{
    const steps = useMemo(() => stepsData.map(item => item.description), []);  // ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
    //#region screen controls

    return (
        <div className="flex justify-between items-center w-full pb-6">
            {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && (
                            // Line before the step circle
                            <div className={`flex-auto h-1 mt-8 ${isCompleted ? 'bg-primary' : 'bg-gray-300'}`}></div>
                        )}
                        <div className="flex flex-col items-center">
                            <span className="text-xs mt-2 font-semibold pb-2">{`Step ${index + 1}`}</span> {/* Step label */}
                            <div className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${isCompleted ? 'bg-primary' : 'bg-gray-300'}`}>
                                {isCompleted ? (
                                    // Checkmark for completed steps
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                ) : (
                                    // Step number for non-completed steps
                                    <span className="text-sm font-semibold">{index + 1}</span>
                                )}
                            </div>
                        </div>
                        {index !== steps.length - 1 && (
                            // Line after the step circle
                            <div className={`flex-auto h-1 mt-8 ${index < currentStep - 1 ? 'bg-primary' : 'bg-gray-300'}`}></div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default React.memo(StepTracker);