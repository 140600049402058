import { useCallback, startTransition } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useNavigationLogic = (beforeNavFunc, validationFunc, state) => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateToNextPage = useCallback((event) => {
        if (event) event.preventDefault();

        if (beforeNavFunc) beforeNavFunc();

        if (validationFunc && !validationFunc()) {
            return null;
        }

        const sequenceNo = Number(sessionStorage.getItem('sequenceNo')) || 0;
        const processFlow = JSON.parse(sessionStorage.getItem('processFlow')) || [];
        const currentStep = processFlow[sequenceNo]; // Get the current step from the process flow using the sequence number

        if (currentStep && currentStep.nextPageUrl) {
            // console.log('Navigating to next page :' + currentStep.nextPageUrl);
            startTransition(() => {
                // Navigate to the next page URL from the process flow
                navigate(currentStep.nextPageUrl, {
                    state: { ...location.state, state },
                }); // Navigate to the next page URL from the process flow
            });
        }
    }, [beforeNavFunc, location.state, navigate, state, validationFunc]);

    return navigateToNextPage;
};

export default useNavigationLogic;
