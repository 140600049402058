import React from "react";
// import BoschLogo from "../../Assets/Desktop/bosch_logo_desktop.png";
import { SlExclamation } from "react-icons/sl";
import { isEmpty } from "lodash";
import useSessionAndFetchData from "../../Hooks/useSessionAndFetchData";
import PageHeader from "../pageHeader/PageHeader";

export default function Errors(props) {
    const { error, resetErrorBoundary } = props;
    const { clientDynamicContent } = useSessionAndFetchData();
    const client = sessionStorage.getItem('currentClient');
    const clientName = isEmpty(client) ? null : JSON.parse(client)?.clientName;
    let mobileLabel = "1300 729 885";
    let mobileValue = "1300729885";
    let email = "enquiries@boschpromotions.com.au";

    if (clientName === 'Bosch Promotions NZ') {
        mobileLabel = "0800 245 704";
        mobileValue = "0800245704";
       email = "enquiries@boschhome.co.nz";
    }
    console.log("Error:", error);
    return (
        <div className="flex flex-col justify-center min-h-screen">
            <div className="mx-auto p-4 bg-white w-full sm:w-1/2 pb-7">
                <PageHeader dynamicContent={clientDynamicContent} currentStep="-1" />
                {/* <div
                    alt="Bosch Header"
                    className="w-full mx-auto bg-center bg-no-repeat bg-cover h-52"
                >
                    <img
                        src="/Assets/Desktop/bosch_logo_desktop.png"
                        alt="Bosch Logo"
                        className="max-w-full max-h-full mx-auto items-center bg-white "
                    />
                </div> */}
                <div className="flex flex-col justify-center items-center space-y-5">
                    <SlExclamation className="Exclamation mb-3" />
                    <h2 className="StageHeading text-center text-xl font-semibold">
                        WHOOPS - SOMETHING WENT WRONG
                    </h2>
                    <p className="text-gray-400 text-center">Please try again later</p>
                    <p className="text-gray-400 text-center">
                        If you continue to experience technical issues, please contact us on{" "}
                        <a
                            href={`tel:${mobileValue}`}
                            className="text-blue-500 hover:text-blue-600"
                        >
                            {mobileLabel}
                        </a>{" "}
                        or email us at{" "}
                        <a
                            href={`mailto:${email}`}
                            className="text-blue-500 hover:text-blue-600"
                        >
                            {email}
                        </a>
                    </p>
                    <button className="btnPrimaryEnabled" onClick={resetErrorBoundary}>
                        Reload Page
                    </button>
                </div>
            </div>
        </div>
    );
}
