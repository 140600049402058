import React from 'react';
import useNavigationLogic from '../../Hooks/useNavigationLogic';

function NextButton({ buttontext = "Next", stylename = "", beforeNavFunc, validationFunc, state }) {
    const navigateToNextPage = useNavigationLogic(beforeNavFunc, validationFunc, state);

    return (
        <button onClick={navigateToNextPage} className={stylename}>
            {buttontext}
        </button>
    );
}

export default NextButton;
