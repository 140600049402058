// In LayoutWithFooter.js
import React from 'react';
import Footer from '../footer/footer';

function LayoutWithFooter({ children }) {
    return(
    <>
        {children}
        <Footer className="h-14" />
    </>);
};

export default LayoutWithFooter;
