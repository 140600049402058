/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './header.css';
import useSessionAndFetchData from '../../Hooks/useSessionAndFetchData';
import ResponsiveImage from '../responsiveImage/ResponsiveImage';


function Header() {
    const { clientDynamicContent } = useSessionAndFetchData();
    if (!clientDynamicContent) return null;
    return (
        <header className="md:fixed top-0 w-full flex justify-around items-center p-0 m-0 z-10" style={{ height: '1rem' }}>
            {clientDynamicContent?.Footerbar_lg && <ResponsiveImage dynamicContent={clientDynamicContent} imageName="Footerbar" />}
        </header>
    );
};

export default Header;

