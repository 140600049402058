import React from 'react';
import StepTracker from '../steptracker/steptracker';
import ResponsiveImage from '../responsiveImage/ResponsiveImage';
import './PageHeader.css';

const PageHeader = ({ dynamicContent, currentStep }) => (
    <div className="flex flex-col">
        <div className="mx-auto bg-white w-2/3 main-logo">
            {dynamicContent?.MainLogo_lg && <ResponsiveImage dynamicContent={dynamicContent} imageName="MainLogo" />}
        </div>
        { currentStep > -1 && <StepTracker currentStep={currentStep} />}
    </div>
);

export default PageHeader;
