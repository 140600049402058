import { useState, useEffect } from 'react';
import axiosFetcher from '../components/AxiosFetcher/AxiosFetcher';

const useSessionAndFetchData = () => {
    const [sessionData, setSessionData] = useState({
        selectedPromo: {},
        personalDetails: {},
        purchaseDetails: {},
        sequenceNo: 0,
        currentProcessFlow: {},
        promotionID: null,
        pageID: null,
    });

    const [dynamicContent, setDynamicContent] = useState({});
    const [clientDynamicContent, setClientDynamicContent] = useState({});
    const [promotionDynamicContent, setPromotionDynamicContent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const clientDynamicContentString = sessionStorage.getItem('clientDynamicContent');
        const clientDynamicContentTemp = clientDynamicContentString ? JSON.parse(clientDynamicContentString) : {};
        const clientDynamicContent = clientDynamicContentTemp ?? {};
        setClientDynamicContent(clientDynamicContent);

        const promotionDynamicContentString = sessionStorage.getItem('promotionDynamicContent');
        const promotionDynamicContentTemp = promotionDynamicContentString ? JSON.parse(promotionDynamicContentString) : {};
        const promotionDynamicContent = promotionDynamicContentTemp ?? {};
        setPromotionDynamicContent(promotionDynamicContent);
        
        const selectedPromoString = sessionStorage.getItem('selectedPromo');
        const selectedPromotemp = selectedPromoString ? JSON.parse(selectedPromoString) : {};
        const selectedPromo = selectedPromotemp?.value ? selectedPromotemp?.value : {};

        const purchaseDetailsString = sessionStorage.getItem('purchaseDetails');
        const purchaseDetails = purchaseDetailsString ? JSON.parse(purchaseDetailsString) : {};
        
        const personalDetailsString = sessionStorage.getItem("personalDetails");
        const personalDetails = personalDetailsString ? JSON.parse(personalDetailsString) : {}

        const sequenceNo = Number(sessionStorage.getItem('sequenceNo'));
        const selectedProcessFlowsString = sessionStorage.getItem('processFlow');
        const selectedProcessFlows = selectedProcessFlowsString ? JSON.parse(selectedProcessFlowsString) : {};
        const currentProcessFlow = selectedProcessFlows[sequenceNo];
        const promotionID = selectedPromo?.id ?? sessionStorage.getItem('promoId');
        const pageID = currentProcessFlow?.startPageID;
    
        setSessionData({
            selectedPromo,
            personalDetails,
            purchaseDetails,
            sequenceNo,
            currentProcessFlow,
            promotionID,
            pageID,
        });
    }, []);

    useEffect(() => {
        // GET ALL Promotion content with no page assigned
        if (!sessionData.promotionID) return;

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const data = await axiosFetcher({
                    url: `/DynamicContent/content/promotion/${sessionData.promotionID}/page/-1`,
                    method: 'GET',
                    enabled: true,
                });

                const newDataObject = data.$values.reduce((acc, item) => {
                    acc[item.area] = item.contentData;
                    return acc;
                }, {});

                sessionStorage.setItem('promotionDynamicContent', JSON.stringify(newDataObject));
                setPromotionDynamicContent(newDataObject);
                setIsLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 404 is not considered an error
                    console.warn('Data not found (404):', error);
                } else {
                    setIsError(true);
                    console.error('Fetching error:', error);
                }
                setIsLoading(false);
            }
        };


        fetchData();
    }, [sessionData.promotionID]);

    useEffect(() => {
        // GET ALL Promotion content for a specifc page
        if (!sessionData.promotionID || !sessionData.pageID) return;

        const fetchData = async () => {
            try {
                setIsLoading(true);

                const data = await axiosFetcher({
                    url: `/DynamicContent/content/promotion/${sessionData.promotionID}/page/${sessionData.pageID}`,
                    method: 'GET',
                    enabled: true,
                });

                const newDataObject = data.$values.reduce((acc, item) => {
                    acc[item.area] = item.contentData;
                    return acc;
                }, {});
                
                setDynamicContent(newDataObject);
                setIsLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 404 is not considered an error
                    console.warn('Data not found (404):', error);
                } else {
                    setIsError(true);
                    console.error('Fetching error:', error);
                }
                setIsLoading(false);
            }
        };


        fetchData();
    }, [sessionData.pageID, sessionData.promotionID]);


    return { sessionData, clientDynamicContent, promotionDynamicContent, dynamicContent, isLoading, isError };
};

export default useSessionAndFetchData;
