import React from 'react';
import parse from 'html-react-parser';
import NextButton from '../nextButton/nextButton';
import { SlExclamation } from 'react-icons/sl';
import ResponsiveImage from '../responsiveImage/ResponsiveImage';

const componentMap = {
    nextbutton: NextButton,
    slexclamation: SlExclamation,
    responsiveimage: ResponsiveImage,
};
const caseSensitiveAttributes = {
    'viewbox': 'viewBox',
    'classname': 'className',
    'dynamiccontent': 'dynamicContent',
};

const transformNode = (node, dynamicContent) => {
    if (node.type === 'tag' && node.name.toLowerCase() in componentMap) {
        const Component = componentMap[node.name.toLowerCase()];
        const props = {};
        // Convert attributes to JSX-compliant props
        Object.keys(node.attribs).forEach(attr => {
            const propKey = caseSensitiveAttributes[attr.toLowerCase()] || attr;
            props[propKey] = node.attribs[attr];
        });
        
        //check if props has dynamicContent attribute    
        if (props.dynamicContent) {
            props.dynamicContent = dynamicContent;
        }

        // console.log(`ComponentName: ${Component.name} props: ${JSON.stringify(props)}`);
        return <Component {...props} />;
    }
};

const DynamicHTMLRenderer = ({ htmlContent, dynamicContent = null }) => {
    return (
        <>
            {parse(htmlContent, {
                replace: (node) => transformNode(node, dynamicContent)
            })}
        </>
    );
};

export default DynamicHTMLRenderer;
