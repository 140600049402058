import React from 'react';


const ResponsiveImage = ({ dynamicContent, imageName, className = "" }) => {

    if (!dynamicContent || !imageName) return null;

    const getImageSource = (size) => dynamicContent[`${imageName}_${size}`];
    // console.log(`ImageSource: ${getImageSource('lg')}`);

    const responsiveImageJSX = (
        <div className={className}>
            <picture>
                {getImageSource('sm') && (
                    <source srcSet={getImageSource('sm')} media="(max-width: 640px)" />
                )}
                {getImageSource('md') && (
                    <source srcSet={getImageSource('md')} media="(max-width: 768px)" />
                )}
                {getImageSource('lg') && (
                    <source srcSet={getImageSource('lg')} media="(max-width: 1280px)" />
                )}
                {getImageSource('xl') && (
                    <source srcSet={getImageSource('xl')} media="(min-width: 1536px)" />
                )}
                {getImageSource('lg') && (
                    <img
                        src={getImageSource('lg')}
                        alt="Dynamic Content"
                    />
                )}
            </picture>
        </div>
    );

    // const responsiveImageHTMLString = ReactDOMServer.renderToString(responsiveImageJSX);
    // console.log(`ResponsiveImage JSX: ${responsiveImageHTMLString}`);

    return responsiveImageJSX;
};

export default ResponsiveImage;
