/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './footer.css';
import useSessionAndFetchData from '../../Hooks/useSessionAndFetchData';
import DynamicHTMLRenderer from '../dynamicHTMLRender/DynamicHTMLRender';
import ResponsiveImage from '../responsiveImage/ResponsiveImage';

function Footer() {
    const { promotionDynamicContent, clientDynamicContent } = useSessionAndFetchData();
    return (
        <>
            <footer
                className="bottom-0 w-full p-4 flex flex-col md:flex-row justify-around items-center text-xs md:text-lg bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: "url('" + clientDynamicContent.Footerbar_lg + "')" }}>
                {promotionDynamicContent.Footer && <DynamicHTMLRenderer htmlContent={promotionDynamicContent.Footer} dynamicContent={clientDynamicContent} />}
            </footer>
        </>
    );
}

export default Footer;
